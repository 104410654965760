
import { Component, Prop, Vue } from 'vue-property-decorator';

interface Kpi {
  labels: string[];
  values: number[];
}

@Component
export default class KpiWidget extends Vue {
  @Prop({ type: Object, required: true }) public item!: Kpi;
}
