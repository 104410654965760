
import { Component, Vue } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import Filter from '@/interfaces/api/Filter';
import OverviewFilter from '@/components/dashboard/OverviewFilter.vue';
import KpiWidget from '@/components/dashboard/KpiWidget.vue';
import { StatisticOverviewItem } from '@/interfaces/models/StatisticOverviewItem';
import OverviewTable from '@/components/dashboard/OverviewTable.vue';
import MetricWidget from '@/components/dashboard/MetricWidget.vue';
import StatisticsApiService from '@/api/http/StatisticsApiService';
import { AxiosResponse } from 'axios';
import { toUrl } from '@/util/helper';
import moment from 'moment';
import { Venue } from '@/interfaces/models/Venue';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import { Permission } from '@/enums/Permission';

const statistics = namespace('statistics');
const app = namespace('app');
const venueLabel = namespace('venueLabel');

@Component({
  components: { MetricWidget, OverviewTable, KpiWidget, OverviewFilter, VWrapper },
})
export default class Dashboard extends Vue {
  @venueLabel.State('items') public venueLabels!: VenueLabel[];
  @venueLabel.Action('fetch') public getVenueLabels!: any;

  @statistics.Action('overview') public getOverview!: any;
  @statistics.Action('setFilter') public setFilter!: any;
  @statistics.State('filter') public filter!: Filter;
  @statistics.State('overview') public items!: StatisticOverviewItem[];

  @app.State('venues') public venues!: Venue[];

  public async mounted() {
    if (this.$can(Permission.LABEL_VIEW)) {
      await this.getVenueLabels();
    }
  }

  public async onFilterChange(filter: Filter) {
    try {
      this.$startLoading('overview');
      this.setFilter(filter);
      await this.getOverview();
    } finally {
      this.$stopLoading('overview');
    }
  }

  get overviewItems() {
    if (this.items) {
      for (const item of this.items) {
        const venue: Venue = this.venues.find((v: Venue) => v._id === item._id)!;
        if (venue) {
          item.readableId = venue.readableId;
        }
      }

      return this.items;
    }

    return [];
  }

  public exportOverviewCsv() {
    this.$startLoading('overview.export');
    const api: StatisticsApiService = new StatisticsApiService();
    api
      .getOverviewCsv({ ...this.filter, lang: this.$i18n.locale })
      .then((res: AxiosResponse) => {
        toUrl(res.data, `overview-${moment().format('DD-MM-YYYY')}.csv`);
      })
      .finally(() => {
        this.$stopLoading('overview.export');
      });
  }

  get totalSum() {
    let sum: number = 0;
    for (const item of this.items) {
      sum += parseFloat(item.total);
    }

    return Math.round((sum + Number.EPSILON) * 100) / 100;
  }

  get totalNetSum() {
    let sum: number = 0;
    for (const item of this.items) {
      sum += parseFloat(item.totalNet);
    }

    return Math.round((sum + Number.EPSILON) * 100) / 100;
  }

  get orderSum() {
    let sum: number = 0;
    for (const item of this.items) {
      sum += parseFloat(item.numberOfOrders);
    }

    return Math.round((sum + Number.EPSILON) * 100) / 100;
  }

  get provisionSum() {
    let sum: number = 0;
    for (const item of this.items) {
      sum += parseFloat(item.provision);
    }

    return Math.round((sum + Number.EPSILON) * 100) / 100;
  }

  /*get overview() {
      if (this.items && this.items.length > 0) {
        const overview: StatisticOverviewItem[] = [];
        const start: Moment = moment(this.filter.from);
        const end: Moment = moment(this.filter.to);

        let next: Moment = start.clone();

        while (next.isSameOrBefore(end, 'day')) {
          const item: StatisticOverviewItem =
          this.items.find((i: StatisticOverviewItem) => moment(i.date).isSame(next, 'day'))!;
          if (!item) {
            overview.push({
              total: 0,
              totalNet: 0,
              numberOfOrders: 0,
              provision: 0,
              date: next.format('DD-MM-YYYY'),
            });
          } else {
            overview.push({ ...item, date: moment(item.date).format('DD-MM-YYYY') });
          }
        }

        return overview;
      }

      return [];
    }

    get numberOfOrders() {
      return {
        labels: this.overview.map((i: StatisticOverviewItem) => i.date),
        values: this.overview.map((i: StatisticOverviewItem) => i.numberOfOrders),
      };
    }

    get provision() {
      return {
        labels: this.overview.map((i: StatisticOverviewItem) => i.date),
        values: this.overview.map((i: StatisticOverviewItem) => i.provision),
      };
    }

    get total() {
      return {
        labels: this.overview.map((i: StatisticOverviewItem) => i.date),
        values: this.overview.map((i: StatisticOverviewItem) => i.total),
      };
    }

    get totalNet() {
      return {
        labels: this.overview.map((i: StatisticOverviewItem) => i.date),
        values: this.overview.map((i: StatisticOverviewItem) => i.totalNet),
      };
    }*/
}
