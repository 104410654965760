
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CustomerGroup from '../../mixins/CustomerGroup';
import { mixins } from 'vue-class-component';
import VRangePicker from '@/components/shared/form/VRangePicker.vue';
import { SelectItem } from '@/interfaces/components/SelectItem';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import VRangePickerOneField from '../shared/form/VDateRangePickerOneField.vue';

@Component({
  components: { VRangePicker, VRangePickerOneField },
})
export default class OverviewFilter extends mixins(CustomerGroup) {
  @Prop({ type: Array, default: () => [] }) public labels!: VenueLabel[];

  public customerGroup: CustomerGroup | null = null;
  public range: { from: string; to: string } | null = null;
  public selectedLabel: string | null = null;

  get selection() {
    let s: any = null;
    if (this.range) {
      s = { ...this.range };
      if (this.customerGroup) {
        s.group = this.customerGroup;
      }
      if (this.selectedLabel) {
        s.venueLabel = this.selectedLabel;
      }
    }

    return s;
  }

  public mounted(): void {
    if (this.$isAdmin()) {
      return;
    }
    // if (this.$getCustomerGroup() !== '') {
    //   // @ts-ignore
    //   this.customerGroup = this.$getCustomerGroup();
    // }
  }

  public onChangeRange(event: { from: string; to: string }) {
    this.range = { ...event };
  }

  @Watch('selection')
  public onSelectionChange() {
    if (this.selection) {
      this.$emit('change', this.selection);
    }
  }

  get customerGroupsWithEmpty() {
    return [{ text: this.$t('common.noSelection'), value: null }, ...this.customerGroups];
  }

  get venueLabelsWithEmpty() {
    return [{ name: this.$t('common.noSelection'), slug: null }, ...this.labels];
  }
}
