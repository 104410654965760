export default [
  {
    text: 'dashboard.table.venue',
    value: 'venueName',
  },
  {
    text: 'dashboard.table.readableId',
    value: 'readableId',
  },
  {
    text: 'dashboard.table.numberOfOrders',
    value: 'numberOfOrders',
  },
  {
    text: 'dashboard.table.total',
    value: 'total',
  },
  {
    text: 'dashboard.table.totalNet',
    value: 'totalNet',
  },
  {
    text: 'dashboard.table.provision',
    value: 'provision',
  },
];
