
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MetricWidget extends Vue {
  @Prop({ type: [String, Number], required: true }) public value!: string;
  @Prop({ type: Boolean, default: false }) public loading!: boolean;
  @Prop({ type: String }) public label!: string;
  @Prop({ type: String }) public color!: string;
  @Prop({ type: String, default: null }) public text!: string;

  get classes() {
    if (this.text) {
      return { [this.text]: true };
    }

    return {};
  }
}
