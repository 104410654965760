
import { Component, Vue, Prop } from 'vue-property-decorator';
import { VMenu } from 'vuetify/lib';
import TimeSelector from '@/components/analytics/TimeSelector.vue';
import { Venue } from '@/interfaces/models/Venue';
import { namespace } from 'vuex-class';
import moment from 'moment';

const venue = namespace('venue');

@Component({
  components: { TimeSelector },
})
export default class VRangePickerOneField extends Vue {
  @venue.State('active') public activeVenue!: Venue;

  public $refs!: {
    fromMenu: InstanceType<typeof VMenu> & { save: (form: string[]) => boolean };
  };
  @Prop({ type: Boolean, default: true }) public initData!: boolean;

  public form: string[] = [];
  public timeFromInput = { openedAt: '', closedAt: '' };

  public handleFormChange() {
    this.form.sort((a: string, b: string) => new Date(a).getTime() - new Date(b).getTime());
    const [from, to] = this.form;
    if (!from && !to) return;
    this.$refs.fromMenu.save(this.form);
    if (this.timeFromInput && this.timeFromInput.closedAt && this.timeFromInput.openedAt) {
      this.emitTime(this.timeFromInput);
    } else {
      this.$emit('change', {
        from,
        to,
      });
    }
  }

  get range() {
    if (this.form.length) {
      this.form.sort((a: string, b: string) => new Date(a).getTime() - new Date(b).getTime());
      return { from: this.form[0], to: this.form[1] };
    }

    return;
  }

  emitTime($event: { openedAt: string; closedAt: string }) {
    if (!$event.openedAt || !$event.closedAt || !this.form[0] || !this.form[1]) return;

    this.timeFromInput = { ...$event };
    const dateRange = this.range ? this.range : { from: '', to: '' };
    const timeRange = $event;

    let fromDate = moment(dateRange.from);
    let toDate = moment(dateRange.to);

    if (!timeRange.openedAt.length) {
      return { from: fromDate, to: toDate };
    }

    const openedAt = moment.utc(timeRange.openedAt, 'HH:mm');
    const closedAt = moment.utc(timeRange.closedAt, 'HH:mm');

    fromDate = fromDate.set({ hour: openedAt.hours(), minute: openedAt.minutes() });
    toDate = toDate.set({ hour: closedAt.hours(), minute: closedAt.minutes() });

    if (toDate.isBefore(fromDate)) {
      toDate = toDate.add(1, 'day');
    }

    const preparedRange = { from: fromDate.format('YYYY-MM-DDTHH:mm'), to: toDate.format('YYYY-MM-DDTHH:mm') };

    this.form = [fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD')];

    this.$emit('change', {
      ...preparedRange,
    });
  }
}
