
import { Component, Prop, Vue } from 'vue-property-decorator';
import VTable from '../shared/table/VTable.vue';
import { StatisticOverviewItem } from '@/interfaces/models/StatisticOverviewItem';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import headers from './headers';

@Component({
  components: { VTable },
})
export default class OverviewTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: StatisticOverviewItem[];
  public headers: ColumnHeader[] = headers;

  get tableHeaders() {
    return this.headers
      .filter((h: ColumnHeader) => {
        if (this.$isAdmin()) {
          return true;
        }
        return h.value !== 'provision';
      })
      .map((h: ColumnHeader) => ({ ...h, text: this.$t(h.text) }));
  }
}
